import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from "react-data-table-component";
import { PRO_BASE_URL } from "./pro_config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../pages/loader";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { param } from "jquery";
import Category from "./category";

const AddCategory = (props) => {
  const { pid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableCol, setTableCol] = useState([]);
  useEffect(() => {
    abc();
  }, []);
  const [selectedValue, setSelectedValue] = useState("");

  const initialState = {
    proname: "",
    id: "", // Add images array to state
  };

  const [state, setState] = useState(initialState);
  const { proname, id, images } = state;

  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setState({ ...state, [name]: value });
    //console.log(state);
  };
  const abc = () => {
    axios
      .post(PRO_BASE_URL + "get_data.php")
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTableData(data.data.category);
          setTableCol(data.data.collection);
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
  };

  const editData = (id) => {
    //console.log(id);
    axios
      .post(PRO_BASE_URL + "get_data.php", { id: id })
      .then(function (data) {
        if (data.data.message == "radha") {
          const productData = data.data.category[0];
          //console.log(productData);

          setState({
            id: productData.id,
            proname: productData.name,

            images: productData.img,
          });
          //console.log(state);
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
  };
  const deletes = (id) => {
    axios
      .post(PRO_BASE_URL + "del_data.php", { id: id, data: "category" })
      .then(function (data) {
        //console.log(data);
        if (data.data.message === "radha") {
          setTmsg("Data Deleted Successfully");
          abc();
        } else if (data.data.message === "FIRST") {
          setTmsg("First Transaction can not delete");
        } else {
          setTmsg("Data Not Delete");
        }
        setTimeout(() => {
          setTmsg("");
        }, 5000);
      })

      .catch((error) => console.error(error));
  };
  const [editId, setEditId] = useState("");
  const columns = [
    {
      name: "id",
      selector: (row) => row["id"],
      sortable: true,
      filterable: true,
    },

    {
      name: "Category",
      selector: (row) => row["name"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Image",
      cell: (row) => <img src={row.img} alt="Image" height={50} width={50} />,
      sortable: true,
      filterable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <img
          src="assets/images/pen.png"
          onClick={() => editData(row.id)}
          height={20}
          width={20}
        />
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <img
          src="assets/images/bin.png"
          data-bs-toggle="modal"
          data-original-title="test"
          onClick={() => setSelectedId(row.id)}
          data-bs-target="#exampleModal"
          height={20}
          width={20}
        />
      ),
    },
  ];
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = tableData.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);
    //console.log(imagesArray);

    setState({ ...state, images: imagesArray });
    selectedImages(imagesArray);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const [tmsg, setTmsg] = useState("");
  const AddProduct = async (e) => {
    // e.preventDefault();

    const formData = new FormData();
    formData.append("id", state.id);
    formData.append("image", selectedFile);
    formData.append("proname", state.proname);

    try {
      const response = await axios.post(
        PRO_BASE_URL + "add_category.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log("Image uploaded successfully:", response);
      if (response.data.message == "radha") {
        setSelectedFile(null);
        abc();
        setState({ proname: "", images: "" });
        setTmsg("Category Added Successfully...");
      }
      setTimeout(() => {
        setTmsg("");
      }, 5000);
    } catch (error) {
      console.error("Error uploading image:", error);
      setTmsg("Try Again");
      setTimeout(() => {
        setTmsg("");
      }, 5000);    }
  };

  const [isTrue, setIsTrue] = useState(false);

  const handleChangeTog = () => {
    setIsTrue(!isTrue);
  };

  const Cancel = () => {
    setSelectedFile(null);
    setState({ proname: "", images: "" });
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">Are You Sure ?</div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={() => deletes(selectedId)}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="page-title">
              <div class="row">
                <div class="col-sm-6">
                  <h3>Add Category</h3>
                  {/* {state} */}
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="https://admin.pixelstrap.com/enzo/template/index.html">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                      </a>
                    </li>
                    <li class="breadcrumb-item active">Add Category</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid add-product">
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="product-info">
                      {/* <h5>Description</h5> */}
                      <form>
                        <div class="product-group">
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="mb-3">
                                <label class="form-label">Category Name</label>
                                <input
                                  class="form-control"
                                  placeholder="Enter Product Name"
                                  type="text"
                                  onChange={handleChange}
                                  name="proname"
                                  value={state.proname}
                                />
                                <span class="text-danger"></span>
                              </div>
                            </div>
                          </div>

                          {/* <button 
        className={`switch ${isTrue ? 'on' : 'off'}`}
        onClick={handleChangeTog}
        type="button"
      >
        {isTrue ? 'Yes' : 'No'}
      </button> */}
                          <p
                            style={{
                              textAlign: "center",
                              background: "#8b0000",color:"white"
                            }}
                          >
                            {tmsg}
                          </p>

                          <div class="row">
                            <div class="col-sm-12">
                              <div class="mb-3">
                                <label class="form-label">Category Image</label>
                                <br />

                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange}
                                />
                                {selectedFile && (
                                  <img
                                    src={URL.createObjectURL(selectedFile)}
                                    alt="Selected File"
                                    style={{
                                      width: "200px",
                                      height: "auto",
                                      margin: "5px",
                                    }}
                                  />
                                )}
                                {state.images && (
                                  <img
                                    src={state.images}
                                    alt={`image`}
                                    style={{
                                      width: "200px",
                                      height: "auto",
                                      margin: "5px",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="mt-4">
                            <div class="row">
                              <div class="col-sm-12 text-end">
                                <a
                                  class="btn btn-primary me-3"
                                  onClick={() => AddProduct()}
                                >
                                  ADD{" "}
                                </a>
                                <a
                                  class="btn btn-secondary"
                                  onClick={() => Cancel()}
                                >
                                  Cancel
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      {/* <h5 class="mt-4">Categories</h5> */}
                      {/* <form>
                                                <div class="product-group">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Product Category</label>
                                                                <select class="form-select" onChange={handleChange} name="category" value={state.category}>
                                                                    <option>Select..</option>
                                                                    {tableData.map((item, index) => (
                                                                        <option value={item.id} key={index}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="mb-3">
                                                                <label class="form-label">Gender</label>
                                                                <select class="form-select" onChange={handleChange} value={state.gender} name="gender">
                                                                    <option>Select..</option>
                                                                    <option>Male</option>
                                                                    <option>Female</option>
                                                                    <option>Unisex</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="mb-3">
                                                                <label class="form-label">Collection</label>
                                                                <select class="form-select" onChange={handleChange} value={state.collection}>
                                                                    <option>Select..</option>
                                                                    {tableCol.map((item, index) => (
                                                                        <option value={item.id} name="collection" onChange={(e) => alert(e.target.value)} key={index}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Quality</label>
                                                                <select class="form-select">
                                                                    <option>Brand New</option>
                                                                    <option>Second Hand</option>
                                                                    <option>Both Quality</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-xl-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="product-info">
                                            <h5>Product Image</h5>
                                            {/* <form class="dropzone dz-clickable" id="singleFileUpload" action="https://admin.pixelstrap.com/upload.php">
                                                <div class="dz-message needsclick"><i class="icon-cloud-up"></i>
                                                    <h6>Drop files here or click to upload.</h6><span class="note needsclick"></span>(This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.)
                                                </div>
                                            </form> 
                                            <input type="file" accept="image/*" onChange={handleImageChange} multiple />
                                            {selectedImages.map((image, index) => (
                                                <img key={index} src={URL.createObjectURL(image)} alt={`image-${index}`} style={{ width: '200px', height: 'auto', margin: '5px' }} />
                                            ))}
                                            <h5 class="mt-4">Select Size</h5>
                                            <form>
                                                <div class="product-group">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="mb-3">
                                                                <label class="form-label">Stock</label>
                                                                <input class="form-control" onChange={handleChange} value={state.stock} name="stock" type="number" placeholder="10" />
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="mb-3">
                                                                <label class="form-label">Status</label>
                                                                <select class="form-select" onChange={handleChange} value={state.status} name="status">
                                                                    <option>Select.. </option>
                                                                    <option>Active</option>
                                                                    <option>Deactive </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="mb-3">
                                                                <label class="form-label">Price</label>
                                                                <input class="form-control" onChange={handleChange} value={state.price} name="price" type="number" />
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="mb-3">
                                                                <label class="form-label">Discount Price</label>
                                                                <input class="form-control" type="number" onChange={handleChange} value={state.discount} name="discount" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="mt-4">
                                            <div class="row">
                                                <div class="col-sm-12 text-end"><a class="btn btn-primary me-3" onClick={() => AddProduct()}>ADD </a><a class="btn btn-secondary">Cancel</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
          <div class="container-fluid">
            <div class="col-sm-12 ">
              <div class="card" style={{ minHeight: "250px" }}>
                {/* <div class="card-header pb-0">
                                    <h5>Category</h5><span></span>
                                </div> */}
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div class="card-body">
                      <div class="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination
                          subHeader
                          // conditionalRowStyles={conditionalRowStyles}
                          // conditionalCellStyles={conditionalRowStyles}
                          // customStyles={customStyles}
                          subHeaderComponent={
                            <input
                              type="text"
                              placeholder="Search"
                              class="form-control"
                              style={{ width: "200px" }}
                              value={searchText}
                              onChange={handleSearch}
                            />
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
