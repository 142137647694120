import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PRO_BASE_URL } from "./pro_config";

const ProLogin = () => {
  const [mobile, setMobile] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [errors, setErrors] = useState("");

  const handleChange = (e) => {
    setMobile(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  // useEffect =() =>{
  //     setTimeout(() => history.push("/"), 250);
  // }

  const [isLoading, setIsLoading] = useState(false);
  const [tmsg, setTmsg] = useState("");
  const Checkuser = (e) => {
    e.preventDefault();

    if (!mobile || !password) {
      setTmsg("Please Provide Mobile number and Password.");
      setTimeout(() => {
        setTmsg("");
      }, 5000);
    } else {
      setIsLoading(true);
      axios
        .post(PRO_BASE_URL + "admin_login.php", {
          mobile: mobile,
          password: password,
        })
        .then(function (data) {
          console.log(data);
          if (
            data.data.message === "Invalid User or password" ||
            data.data.message === "invalid mobile number" ||
            data.data.message === "Invalid password format" ||
            data.data.message === "Password is incorrect"
          ) {
            setTmsg("Invalid Mobile number and Password.");
            setTimeout(() => {
              setTmsg("");
            }, 5000);
            setIsLoading(false);
          } else if (data.data.message == "radha") {
            setIsLoading(false);
            setTmsg("Successfully Logged In....");
            setTimeout(() => {
              setTmsg("");
            }, 5000);
            localStorage.setItem("user", data.data.data.data.admin_name);
            localStorage.setItem("aid", data.data.data.data.admin_id);
            setTimeout(() => history.push("/AddCategory"), 0);
          } else {
            setTmsg("Invalid Mobile number and Password.");
            setTimeout(() => {
              setTmsg("");
            }, 5000);
            setIsLoading(false);
          }
        });
    }
  };

  if (
    localStorage.getItem("aid") != null &&
    localStorage.getItem("aid") != ""
  ) {
    history.push("/AddProducts"); // Redirect to login page
  }

  return (
    <div>
      <body>
        <div class="container-fluid p-0">
          <div class="row m-0">
            <div class="col-12 p-0">
              <div class="login-card">
                <div>
                  <ToastContainer />
                  <p
                    style={{
                      textAlign: "center",
                      background: "#8b0000",
                      color:"white"
                    }}
                  >
                    {tmsg}
                  </p>
                  <div class="logo">
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src="../assets/images/MLOGO.png"
                      alt="Mangalik Gold"
                    />
                    <h3>Ecommerce Login</h3>
                  </div>
                  <div class="login-main">
                    <form class="theme-form" onSubmit={Checkuser}>
                      {/* <h4 class="text-center">Sign in to account</h4> */}
                      {/* <p class="text-center">Enter your Mobile number & password to login</p> */}
                      {/* <div class="danger" style={{ color: 'red' }}>{errors}</div> */}

                      <div class="form-group">
                        <label class="col-form-label">Mobile No</label>
                        <input
                          class="form-control"
                          type="text"
                          value={mobile}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required=""
                          placeholder="Please Enter Mobile No"
                        />
                      </div>
                      <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <div class="form-input position-relative">
                          <input
                            class="form-control"
                            type="text"
                            value={password}
                            onChange={(e) => {
                              handlePasswordChange(e);
                            }}
                            placeholder="Please Enter Password"
                          />
                          {/* <div class="show-hide"><span class="show">                         </span></div> */}
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        {isLoading == false ? (
                          <div class="text-end mt-3">
                            <input
                              type="submit"
                              class="btn btn-primary btn-block w-100"
                              value="Login"
                            />
                          </div>
                        ) : (
                          <div class="text-end mt-3">
                            <input
                              type="submit"
                              class="btn btn-primary btn-block w-100 disabled"
                              value="Login"
                            />
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div class="row" style={{ textAlign: "center" }}>
                <div class="col-4" style={{ color: "red", textAlign: "start" }}>
                  <p style={{ fontSize: "20px" }} onClick={() => agent()}>
                    Agent Login{" "}
                  </p>
                </div>
                <div class="col-4" style={{ color: "red" }}>
                  <p style={{ fontSize: "20px" }} onClick={() => change()}>
                    Mandal Login{" "}
                  </p>
                </div>
                <div class="col-4" style={{ textAlign: "end", color: "red" }}>
                  <p style={{ fontSize: "20px" }} onClick={() => ecom()}>
                    ECommerce Login{" "}
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default ProLogin;
