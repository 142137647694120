import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";
import { PRO_BASE_URL } from "./pro_config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../pages/loader";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { param } from "jquery";

const AddProducts = () => {
  const url = window.location.href; // Get the current URL
  const datas = url.split("?");
  const id = datas[1];
  // setId(data[1])
  const pid = datas[1];
  // const pid = "";
  //console.log(pid);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableCol, setTableCol] = useState([]);
  useEffect(() => {
    if (pid != "" && pid != null) {
      getEdit();
    }
    abc();
  }, [pid]);
  const [selectedValue, setSelectedValue] = useState("");

  const [fixed, setFixed] = useState(true);

  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const [tmsg,setTmsg] = useState("")

  const [editData, setEditData] = useState("");
  const getEdit = () => {
    axios
      .post(PRO_BASE_URL + "get_pro_detail.php", { pid: pid })
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          const productData = data.data.result[0];
          setState({
            id: pid,
            proname: productData.title,
            desc: productData.desc,
            category: productData.category,
            collection: productData.collection,
            gender: productData.gender,
            stock: productData.stock,
            code: productData.code,
            status: productData.status,
            price: productData.price,
            discount: productData.discount,
            net: productData.net,
            gross: productData.gross,
            d_gm: productData.d_gm,
            d_karat: productData.d_karat,
            images: productData.images, // Assuming images will be handled separately
          });
          if (productData.brand != "Silver") {
            setFixed(false);
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
  };

  const initialState = {
    proname: "",
    code: "",
    desc: "",
    category: "",
    collection: "",
    gender: "",
    stock: "",
    status: "",
    price: "",
    discount: "",
    d_gm: "",
    d_karat: "",
    net: "",
    gross: "",
    images: [], // Add images array to state
  };

  const [state, setState] = useState(initialState);
  const {
    proname,
    code,
    desc,
    category,
    collection,
    gender,
    stock,
    status,
    price,
    discount,
    date,
    images,
    d_gm,
    d_karat,
    net,
    gross,
  } = state;

  //console.log(state);

  const [selectedFile, setSelectedFile] = useState([]);
  const handleImageChange = (event) => {
    const files = event.target.files;
    setSelectedFile(Array.from(event.target.files));
  };
  const abc = () => {
    axios
      .post(PRO_BASE_URL + "get_data.php")
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTableData(data.data.category);
          setTableCol(data.data.material);
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
  };

  const AddProduct = async (e) => {
    // e.preventDefault();

    const formData = new FormData();
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append("images[]", selectedFile[i]);
    }
    formData.append("id", state.id);
    formData.append("proname", state.proname);
    formData.append("stock", state.stock);
    formData.append("status", state.status == "0" ? 0 : 1);
    formData.append("collection", state.collection);
    formData.append("category", state.category);
    formData.append("price", state.price);
    formData.append("discount", state.discount);
    formData.append("desc", state.desc);
    formData.append("gender", state.gender);
    formData.append("d_gm", state.d_gm);
    formData.append("d_karat", state.d_karat);
    formData.append("net", state.net);
    formData.append("code", state.code);
    formData.append("gross", state.gross);

    try {
      const response = await axios.post(
        PRO_BASE_URL + "add_product.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(response.data);
      if (response.data.message == "radha") {
        // toast.success("Product Added Successfuly....");
        setTmsg("Product Added Successfuly....");
        setTimeout(() => {
          setTmsg("");
        }, 5000);
        setState({
          proname: "",
          stock: "",
          status: "",
          price: "",
          stock: "",
          gender: "",
          d_gm: "",
          d_karat: "",
          desc: "",
          net: "",
          code: "",
          gross: "",
          category: "",
          collection: "",
          discount: "",
        });
        setSelectedFile([]);
      } else {
        setTmsg("Product Added Successfuly....");
        setTimeout(() => {
          setTmsg("");
        }, 5000);
        console.error("Product Not Added Successfuly....");
      }
      // Handle success: show a success message, update UI, etc.
    } catch (error) {
        setTmsg("Product Not Added Successfuly....");
        setTimeout(() => {
          setTmsg("");
        }, 5000);
      console.error("Error uploading image:", error);
      // Handle error: show an error message, retry upload, etc.
    }
  };

  const Cancel = () => {
    setState({
      proname: "",
      stock: "",
      status: "",
      price: "",
      stock: "",
      gender: "",
      d_gm: "",
      d_karat: "",
      desc: "",
      net: "",
      code: "",
      gross: "",
      category: "",
      collection: "",
      discount: "",
    });
    setSelectedFile([]);
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div className="page-body-wrapper">
        <ToastContainer />
        <div className="page-body" style={{ marginLeft: "320px" }}>
          <p
            style={{
              textAlign: "center",
              background: "#8b0000",color:"white"
            }}
          >
            {tmsg}
          </p>

          <div className="container-fluid">
            <div className="page-title">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Add Product</h3>
                  {/* {state} */}
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="https://admin.pixelstrap.com/enzo/template/index.html">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                      </a>
                    </li>
                    <li className="breadcrumb-item active">Add Product</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid add-product">
            <div className="row">
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-body">
                    <div className="product-info">
                      <h5>Description</h5>
                      <form>
                        <div className="product-group">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="mb-3">
                                <label className="form-label">
                                  Product Name
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Product Name"
                                  type="text"
                                  onChange={handleChange}
                                  name="proname"
                                  value={state.proname}
                                />
                                <span className="text-danger"></span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="mb-3">
                                <label className="form-label">
                                  Product Description
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Product Description"
                                  onChange={handleChange}
                                  name="desc"
                                  value={state.desc}
                                  type="text"
                                />
                                <span className="text-danger"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <h5 className="mt-4">Categories</h5>
                      <form>
                        <div className="product-group">
                          <div className="row">
                            <div className="col-sm-6">
                              <label className="form-label">
                                Product Category
                              </label>
                              <select
                                className="form-select"
                                onChange={handleChange}
                                name="category"
                                value={state.category}
                              >
                                <option>Select..</option>
                                {tableData.map((item, index) => (
                                  <option
                                    value={item.id}
                                    selected={item.id == state.category}
                                    key={index}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">Gender</label>
                                <select
                                  className="form-select"
                                  onChange={handleChange}
                                  value={state.gender}
                                  name="gender"
                                >
                                  <option>Select..</option>
                                  <option>Male</option>
                                  <option>Female</option>
                                  <option>Unisex</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <label className="form-label">
                                Product Image (3 images)
                              </label>
                              <br />

                              <input
                                type="file"
                                accept="image/*"
                                max={3}
                                onChange={handleImageChange}
                                multiple
                              />

                              {selectedFile ? (
                                selectedFile.map((imageSets, index) => (
                                  <img
                                    key={index}
                                    src={URL.createObjectURL(imageSets)}
                                    alt={`image-${index}`}
                                    style={{
                                      width: "200px",
                                      height: "auto",
                                      margin: "5px",
                                    }}
                                  />
                                ))
                              ) : (
                                <></>
                              )}
                              {state.images && (
                                <>
                                  {state.images.map((imageSet, index) => (
                                    // imageSet.map((imgs, index) => (

                                    <img
                                      key={index}
                                      src={imageSet.src}
                                      alt={`image-${index}`}
                                      style={{
                                        width: "200px",
                                        height: "auto",
                                        margin: "5px",
                                      }}
                                    />
                                    // ))
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                          {/* <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="mb-3">
                                                                <label className="form-label">Quality</label>
                                                                <select className="form-select">
                                                                    <option>Brand New</option>
                                                                    <option>Second Hand</option>
                                                                    <option>Both Quality</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-body">
                    <input
                      type="checkbox"
                      className="form-input-check"
                      onClick={() => setFixed(!fixed)}
                      checked={fixed ? "checked" : ""}
                    />
                    <label className="form-label">Fixed Price</label>
                    <div className="product-info">
                      <h5>Select Material</h5>

                      <div className="product-group">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Material</label>
                              <select
                                className="form-select"
                                name="collection"
                                onChange={handleChange}
                                value={state.collection}
                              >
                                <option>Select..</option>
                                {tableCol.map((item, index) => (
                                  <option
                                    value={item.id}
                                    selected={item.id == state.collection}
                                    key={index}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Product Code</label>
                              <input
                                className="form-control"
                                onChange={handleChange}
                                value={state.code}
                                name="code"
                                type="text"
                                placeholder="product code"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Stock</label>
                              <input
                                className="form-control"
                                onChange={handleChange}
                                value={state.stock}
                                name="stock"
                                type="number"
                                placeholder="SKU"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <select
                                className="form-select"
                                onChange={handleChange}
                                value={state.status}
                                name="status"
                              >
                                <option>Select.. </option>
                                <option value={0}>Active</option>
                                <option value={1}>Deactive </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5 className="mt-4">Pricing</h5>
                      {fixed ? (
                        <>
                          <form>
                            <div className="product-group">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <label className="form-label">Price</label>
                                    <input
                                      className="form-control"
                                      onChange={handleChange}
                                      value={state.price}
                                      name="price"
                                      type="number"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Discount Price
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      onChange={handleChange}
                                      value={state.discount}
                                      name="discount"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </>
                      ) : (
                        <form>
                          <div className="product-group">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Gross Weight
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={handleChange}
                                    value={state.gross}
                                    name="gross"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Net Weight
                                  </label>
                                  <input
                                    className="form-control"
                                    onChange={handleChange}
                                    value={state.net}
                                    name="net"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Diamond Karat
                                  </label>
                                  <input
                                    className="form-control"
                                    onChange={handleChange}
                                    value={state.d_karat}
                                    name="d_karat"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Diamond Gm
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={handleChange}
                                    value={state.d_gm}
                                    name="d_gm"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-sm-12 text-end">
                          <a
                            className="btn btn-primary me-3"
                            onClick={(e) => AddProduct(e)}
                          >
                            ADD{" "}
                          </a>
                          <a
                            className="btn btn-secondary"
                            onClick={() => Cancel()}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProducts;
