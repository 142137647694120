import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from "react-data-table-component";
import { PRO_BASE_URL } from "./pro_config";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../pages/loader";
import AddCategory from "./add_product";

const OrderGold = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [selectedId, setSelectedId] = useState("");
  const [design, setDesign] = useState("");
  const [size, setSize] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [tableData, setTableData] = useState([]);
  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("aid");
  var websess = localStorage.getItem("web");

  ////console.log(sesspass)

  useEffect(() => {
    // Web();
    abc();
  }, []);

  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("aid");
  var websess = localStorage.getItem("web");
  const web = localStorage.getItem("web");

  const Web = () => {
    const token = localStorage.getItem("token");
    const dObject = {
      input: { username: "Milan@1523", password: "15231523" },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    //console.log(deleteData);
    axios
      .post(PRO_BASE_URL + "web_auth.php", deleteData)
      .then(function (data) {
        const abc = data.data.web;
        if (abc !== web) {
          // localStorage.setItem('user', '')
          // localStorage.setItem('token', '')
          // localStorage.setItem('pass', '')
          // localStorage.setItem('aid', '')
          // localStorage.setItem('margin', '')
          // localStorage.setItem('web', '')
          // toast.error('You can not use on multiple device at a same time', { position: toast.POSITION.TOP_RIGHT });
          // window.location.reload(false);
        }
        ////console.log(data)
      })
      .catch((error) => console.error(error));
  };

  const abc = () => {
    axios
      .post(PRO_BASE_URL + "get_order_gold.php")
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTableData(data.data.result);
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
    // setIsLoading(false);
  };

  const [tmsg, setTmsg] = useState("");

  const deletes = (action) => {
    axios
      .post(PRO_BASE_URL + "handle_gold_order.php", {
        oid: selectedId,
        action: action,
        size:size,
        design:design
      })
      // .then(response => response.json())
      .then(function (data) {
        console.log(data);

        if (data.data.message === "radha") {
          setTmsg(`Order ${action} Successfully`);
          // toast.susccess(`Order ${action} Successfully`);
          abc();
        } else if (
          data.data.message === "invalid" &&
          data.data.data["message"] === "Wallet balance is low."
        ) {
          setTmsg(data.data.data["message"]);
        } else {
          setTmsg(data.data.data["message"]);
        }
        setTimeout(() => {
          setTmsg("");
        }, 5000);
      })

      .catch((error) => console.error(error));
  };
  const coolor = "Withdraw";

  const getBackgroundColor = (row) => {
    switch (row.st) {
      case "delivered":
      case "inhouse":
        return "#8b0000";
      case "pending":
        return "#c3e0b8";
      case "approve":
        return "#8b0000";
      case "reject":
      case "cancelled":
      case "exception":
        return "black";
      default:
        return "black"; // Default color if status doesn't match
    }
  };

  const columns = [
    {
      name: "Customer Name",
      selector: (row) => row["name"],
      sortable: true,
      filterable: true,
    },
   
    {
      name: "Date",
      selector: (row) => row["date"],
      sortable: true,
      filterable: true,
    },
    {
      name: "OrderId",
      selector: (row) => "#" + row["orderid"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Payment Type",
      selector: (row) => row["paymethod"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row["mobile"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Metal",
      selector: (row) => row["metal"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Metal Price",
      selector: (row) => "₹ " + row["mprice"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Weight (gm)",
      selector: (row) => row["weight"],
      sortable: true,
      filterable: true,
    },

    // {
    //   name: "Address",
    //   selector: (row) => row["address"],
    //   sortable: true,
    //   filterable: true,
    // },

    {
      name: "Total Price",
      selector: (row) => "₹ " + row["price"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Advance",
      width:"200px",
      selector: (row) => "₹ " + row["advance"] + " (" + row["percent"] + "%)",
      sortable: true,
      filterable: true,
    },
    {
      name: "Remark",
      selector: (row) => row["remark"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Reference",
      selector: (row) => row["ref"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.st,
      sortable: true,
      filterable: true,
      hide: true,
      cell: (row) => (
        <div
          style={{
            backgroundColor: getBackgroundColor(row),
            borderRadius: "10px",
            padding: "5px",
            // opacity:"50%",
            color: "white",
          }}
        >
          {row.st}
        </div>
      ),
    },
    // {
    //   name: "View",
    //   button: true,
    //   cell: (row) => (
    //     <Link to={`/order_details?${row.orderid}`}>
    //       <img src="assets/images/show.png" height={20} width={20} />
    //     </Link>
    //   ),
    // },
    {
      // name: 'Action', button: true, cell: (row) => (<img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.id)} data-bs-target="#exampleModal" height={20} width={20} />
      name: "Action",
      button: true,
      cell: (row) => (
        <img
          src="assets/images/pen.png"
          height={20}
          width={20}
          data-bs-toggle="modal"
          data-original-title="test"
          onClick={() => {
            setSelectedId(row.orderid);
            setSelectedStatus(row.st);
          }}
          data-bs-target="#exampleModal"
        />
      ),
    },
  ];
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = tableData.filter(
    (item) =>
      item.price.toLowerCase().includes(searchText.toLowerCase()) ||
      item.st.toLowerCase().includes(searchText.toLowerCase()) ||
      item.orderid.toLowerCase().includes(searchText.toLowerCase()) ||
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.paymethod.toLowerCase().includes(searchText.toLowerCase())
  );

  // const conditionalRowStyles = (row) => {
  //     // You can use the row index to determine if it's even or odd
  //     const index = filteredData.indexOf(row);
  //     return index % 2 === 0 ? { backgroundColor: 'green' } : { backgroundColor: 'red' };
  //   };
  const customStyles = {
    cells: {
      style: {}, // Add common styles for all cells in the 'Type' column if needed
      classes: "badge badge-primary", // Apply the class to all cells in the 'Type' column
    },
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">Order id : #{selectedId}
                <br />
                <label className="mt-2"> Design</label>
                <input type="text" className="form-control" placeholder="Design" onChange={(e)=>setDesign(e.target.value)} value={design}/>
                <label className="mt-2">Size</label>
                <input type="text" className="form-control" placeholder="Size" onChange={(e)=>setSize(e.target.value)} value={size}/>
              </div>
              <div class="modal-footer">
                 {selectedStatus === "approve" && <button
                    class="btn btn-secondary"
                    type="button"
                    onClick={() => deletes("update")}
                    data-bs-dismiss="modal"
                  >
                    Update
                  </button>}
                {(selectedStatus === "pending" ||
                  selectedStatus === "reject") && (
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => deletes("approve")}
                    data-bs-dismiss="modal"
                  >
                    Approve
                  </button>
                )}
                {selectedStatus != "reject" && (
                  <button
                    class="btn btn-dark"
                    type="button"
                    onClick={() => deletes("reject")}
                    data-bs-dismiss="modal"
                  >
                    Reject
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="col-sm-12 ">
              <div class="card" style={{ minHeight: "250px" }}>
                <div class="card-header pb-0">
                  <h5>Gold Booking</h5>
                  <span></span>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <p style={{ textAlign: "center", background: "#8b00000" }}>
                      {tmsg}
                    </p>
                    <div class="card-body">
                      <div class="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination
                          subHeader
                          subHeaderComponent={
                            <input
                              type="text"
                              placeholder="Search"
                              class="form-control"
                              style={{ width: "200px" }}
                              value={searchText}
                              onChange={handleSearch}
                            />
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderGold;
