import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { LogOut } from "react-feather";
import Category from "./Product/category";
import Collection from "./Product/collection";
import AddProducts from "./Product/add_product";
import Products from "./Product/product";
import AddCategory from "./Product/add_category";
import AddCollection from "./Product/add_collection";
import Orders from "./Product/order";
import ProHeader from "./Product/proHeader";
import ProLogin from "./Product/proLogin";
import AddMaterial from "./Product/add_material";
import Prices from "./Product/price";
import Relations from "./Product/Person";
import Occassion from "./Product/occasion";
import HomePage from "./Product/dash.jsx";
import Agents from "./Product/agents.jsx";
import KYC from "./Product/kyc";
import AgentTrans from "./Product/agent_trans";
import Agent_with_req from "./Product/agent_with_req";
import AgentReport from "./Product/agentreport.jsx";
import PriceChange from "./Product/pricechange.jsx";
import UserGold from "./Product/users.jsx";
import OrderGold from "./Product/ordergold.jsx";
import CartData from "./Product/cartData.jsx";

function App() {
  const ab = localStorage.getItem("aid");

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/agents">
            <ProHeader />
            <Agents />
          </Route>
          <Route path="/kyc">
            <ProHeader />
            <KYC />
          </Route>
          <Route path="/agent_transactions">
            <ProHeader />
            <AgentTrans />
          </Route>
          <Route path="/agent_withdraw">
            <ProHeader />
            <Agent_with_req />
          </Route>

          <Route path="/order_details">
            <ProHeader />
            <Category />
          </Route>
          <Route path="/price_change">
            <ProHeader />
            <PriceChange />
          </Route>
          <Route exact path="/">
            <ProLogin />
          </Route>
          <Route path="/agentreport">
            <ProHeader />
            <AgentReport />
          </Route>
          <Route path="/AddProducts">
            <ProHeader />
            <AddProducts />
          </Route>
          <Route path="/AddCategory">
            <ProHeader />
            <AddCategory />
          </Route>
          <Route path="/AddCollection">
            <ProHeader />
            <AddCollection />
          </Route>
          <Route path="/product">
            <ProHeader />
            <Products />
          </Route>
          <Route path="/order">
            <ProHeader />
            <Orders />
          </Route>
          <Route path="/AddMaterial">
            <ProHeader />
            <AddMaterial />
          </Route>
          <Route path="/prices">
            <ProHeader />
            <Prices />
          </Route>
          <Route path="/relations">
            <ProHeader />
            <Relations />
          </Route>
          <Route path="/occassion">
            <ProHeader />
            <Occassion />
          </Route>
          <Route path="/golduser">
            <ProHeader />
            <UserGold />
          </Route>
          <Route path="/goldorder">
            <ProHeader />
            <OrderGold />
          </Route>
          <Route path="/usercart">
            <ProHeader />
            <CartData />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
