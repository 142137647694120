import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from "react-data-table-component";
import { PRO_BASE_URL } from "./pro_config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../pages/loader";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Agent_with_req = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedId, setSelectedId] = useState([]);
  const [refcode, setRefcode] = useState("");
  const [tableData, setTableData] = useState([]);
  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("rid");
  var websess = localStorage.getItem("web");
  // const [filteredData, setFilteredData] = useState(tableData);

  const [isActive, setIsActive] = useState(false);

  const ref = "MYCODE1234";

  const handleCopy = () => {
    const tempInput = document.createElement("input");
    tempInput.value = ref;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 2500);
  };

  ////console.log(sesspass)
  const [tableDatas, setTableDatas] = useState([]);

  useEffect(() => {
    Web();
    abc();
    Users();
  }, []);
  const Users = () => {
    axios.post(PRO_BASE_URL + "get_kyc.php").then(function (data) {
      //console.log(data);
      setTableDatas(data.data.result);
      // setState({
      //   aname: data.data.result.name,
      //   email: data.data.result.email,
      // });
    });
  };

  const deletes = (actions) => {
    axios
      .post(PRO_BASE_URL + "approve_withdraw.php", {
        id: selectedId.wid,
        actions: actions,
      })
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTmsg("Withdrawal Approved");
          setTimeout(() => {
            setTmsg("");
          }, 5000);
          // toast.success("Withdrawal Approved");
          Users();
          abc();
        } else {
          setTmsg("Try Again");
          setTimeout(() => {
            setTmsg("");
          }, 5000);
          // toast.error("Try Again");
        }
      });
  };

  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("aid");
  var websess = localStorage.getItem("web");
  const web = localStorage.getItem("web");
  const history = useHistory();

  const [today, setToday] = useState("");
  const [months, setMonths] = useState("");
  const [years, setYears] = useState("");
  const [yearsall, setYearsall] = useState("");
  const Web = () => {
    axios
      .post(PRO_BASE_URL + "earning.php", { id: aid })
      .then(function (data) {
        const abc = data.data.today;
        setToday(abc);

        setMonths(data.data.month);
        setYears(data.data.year);
        setYearsall(data.data.yearall);
        //console.log(data);
      })
      .catch((error) => console.error(error));
  };

  const abc = () => {
    axios
      .post(PRO_BASE_URL + "get_admin_withdraw.php")
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTableData(data.data.result);

          handleSearchData();
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
    // setIsLoading(false);
  };

  const columns = [
    {
      name: "Agent Name",
      selector: (row) => row["agent"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row["date"],
      sortable: true,
      filterable: true,
    },

    {
      name: "Amount",
      selector: (row) => row["amount"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Request Status",
      selector: (row) => row["req"],
      sortable: true,
      filterable: true,
    },

    {
      name: "Edit",
      button: true,
      cell: (row) =>
        row.emi_type !== "FIRST" && (
          <img
            src="assets/images/pen.png"
            data-bs-toggle="modal"
            data-original-title="test"
            onClick={() => {
              setSelectedId(row);
              setRefcode(row.code);
            }}
            data-bs-target="#exampleModal"
            height={20}
            width={20}
          />
        ),
    },
  ];
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    // filterData(e.target.value);
  };

  const [tmsg,setTmsg] = useState("")

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const [startdate, setStartdate] = useState("2024-01-01");
  const [enddate, setEnddate] = useState(formattedDate);

  const handleSearchData = () => {
    // filterData(searchText);
  };

  const filteredData = tableData.filter((item) => {
    const searchTextMatch = searchText
      ? item.agent.toLowerCase().includes(searchText.toLowerCase()) ||
        item.amount.toString().includes(searchText) ||
        item.req.toLowerCase().includes(searchText.toLowerCase())
      : true;

    return searchTextMatch;
  });

  //   setFilteredData(filtered);

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <p
                  style={{
                    textAlign: "center",
                    background: "#8b0000",color:"white"
                  }}
                >
                  {tmsg}
                </p>
                <div class="row">
                  <h4>Bank Details</h4>
                  <div class="col-md-4">
                    <div>
                      <label>Bank Name</label>
                      <h6>{selectedId.bname}</h6>

                      <label>Account Number</label>
                      <h6>{selectedId.account}</h6>

                      <label>IFSC Number</label>
                      <h6>{selectedId.ifsc}</h6>

                      <label>Branch Name</label>
                      <h6>{selectedId.branch}</h6>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div>
                      <a
                        href={selectedId.bimg}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Passbook / Cheque Image
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                {selectedId.status == "PENDING" && (
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => deletes("approve")}
                    data-bs-dismiss="modal"
                  >
                    Approve
                  </button>
                )}
                <button
                  class="btn btn-secondary"
                  type="button"
                  onClick={() => deletes("reject")}
                  data-bs-dismiss="modal"
                >
                  Reject
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="col-sm-12 ">
              <div class="card" style={{ minHeight: "250px" }}>
                <div class="card-header pb-0">
                  <h5>Agent Withdraw Request</h5>
                  <span></span>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div class="card-body">
                      <div class="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination
                          subHeader
                          subHeaderComponent={
                            <input
                              type="text"
                              placeholder="Search"
                              class="form-control"
                              style={{ width: "200px" }}
                              value={searchText}
                              onChange={handleSearch}
                            />
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agent_with_req;
