import React from "react";

import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PRO_BASE_URL } from "./pro_config";
import Select from "react-select";
import DataTable from "react-data-table-component";

const UserGold = () => {
  const [pname, setPname] = useState("");
  const [errors, setErrors] = useState("");
  const [visible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  // //console.log(limit);
  const [selectedId, setSelectedId] = useState("");
  const [refcode, setRefcode] = useState("");
  const [percevalue, setPercevalue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [tableData, setTableData] = useState([]);
  const [options, setOptions] = useState([]);
  var a_name = localStorage.getItem("user");
  var aid = localStorage.getItem("aid");
  var pass = localStorage.getItem("pass");

  const initialState = {
    uname: "",
    email: "",
    number: "",
    address: "",
    ref: "",
    card: "",
    cnumber: "",
    member: "",
  };
  useEffect(() => {
    // Web();
    abc();
  }, []);

  const [state, setState] = useState(initialState);
  const { uname, email, number, address, ref, card, cnumber, member } = state;

  let name, value;
  const handler = (e) => {
    name = e.target.name;
    value = e.target.value;

    setState({ ...state, [name]: value });
  };
  const search = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: {
        srch: pname,
        page: "AddMoney",
      },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(PRO_BASE_URL + "admin_admin_select_user.php", deleteData)
      .then(function (data) {
        //console.log(data);
        // setTableData(data)
      });
  };
  const [selectedAgentCode, setSelectedAgentCode] = useState("");

  const handleAgentChange = (options) => {
    setSelectedAgentCode(options.value);
  };

  const abc = () => {
    axios
      .post(PRO_BASE_URL + "users.php", { id: aid })
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTableData(data.data.result);
          const optionsData = data.data.result.map((item) => ({
            value: item.mobile,
            label: item.mobile + "-" + item.name,
          }));
          setOptions(optionsData);
          console.log(optionsData);
          //   handleSearchData();
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
    setIsLoading(false);
  };

  const columns = [
    {
      name: "Person Name",
      selector: (row) => row["name"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Gold Percentage",
      selector: (row) => (row["percent"] ? row["percent"] + " %" : ""),
      sortable: true,
      filterable: true,
    },

    {
      name: "Mobile Number",
      selector: (row) => row["mobile"],
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Withdraw",
    //   selector: (row) => row["withdraw"],
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Email Id",
      selector: (row) => row["email"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Date",
      selector: (row) => row["date"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <img
          src="assets/images/pen.png"
          data-bs-toggle="modal"
          data-original-title="test"
          onClick={() => {
            setSelectedId(row.id);
            setPercevalue(row.percent);
            newData(row.date)
          }}
          data-bs-target="#exampleModalCol"
          height={20}
          width={20}
        />
      ),
    },
  ];
  function newData(dates){
    let newDate = dates.split(" ");
    setSelectedDate(newDate[0]);
    setSelectedTime(newDate[1])
  }
  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return titleCase;
  }

  const [tmsg,setTmsg] = useState("")

  const deletes = (page) => {
    console.log(selectedId+selectedTime+selectedDate);
    axios
      .post(PRO_BASE_URL + "user_per.php", { "id":selectedId,"date":selectedDate,"time":selectedTime,"percentage":percevalue })
      .then(function (data) {
        //console.log(data);
        if (data.data.message === "radha") {
          setTmsg("Data Deleted Successfully");
          setTimeout(() => {
            setTmsg("");
          }, 5000);
          abc();
        } else {
          setTmsg("Data Not Delete");
          setTimeout(() => {
            setTmsg("");
          }, 5000);
        }
      })

      .catch((error) => console.error(error));
  };

  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("aid");
  var websess = localStorage.getItem("web");
  const web = localStorage.getItem("web");

  // const Web = () => {
  //   const token = localStorage.getItem("token");
  //   const dObject = {
  //     authorization: token,
  //     input: { aid: aid, auth: websess },
  //   };
  //   const encodedData = btoa(JSON.stringify(dObject));
  //   const deleteData = { data: encodedData };
  //   axios
  //     .post(PRO_BASE_URL + "web_auth.php", deleteData)
  //     .then(function (data) {
  //       const abc = data.data.web;
  //       if (abc !== web) {
  //         localStorage.setItem("user", "");
  //         localStorage.setItem("token", "");
  //         localStorage.setItem("pass", "");
  //         localStorage.setItem("aid", "");
  //         localStorage.setItem("margin", "");
  //         localStorage.setItem("web", "");
  //         toast.error("You can not use on multiple device at a same time", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         window.location.reload(false);
  //       }
  //       //console.log(data)
  //     })
  //     .catch((error) => console.error(error));
  // };

  const Check = (e) => {
    e.preventDefault();
    $(".btn").addClass("disabled");
    const token = localStorage.getItem("token");
    const dObject = {
      authorization: token,
      input: {
        name: toTitleCase(uname),
        mobile: number,
        address: address,
        email: email,
        member: member,
        reference: ref,
        cnumber: cnumber,
        card: card,
        aid: aid,
      },
    };
    const encodedData = btoa(JSON.stringify(dObject));
    const deleteData = { data: encodedData };
    axios
      .post(PRO_BASE_URL + "user_registration.php", deleteData)
      .then(function (data) {
        if (data.data.message == "mobile") {
          toast.error("Please Enter Another Mobile no. ", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (data.data.message == "email") {
          toast.error("Please Enter Another Email ID ", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (data.data.message == "radha") {
          setState({
            uname: "",
            email: "",
            number: "",
            address: "",
            member: "",
            reference: "",
            cnumber: "",
            card: "",
          });
          toast.success("User Registered ", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          $(".btn").removeClass("disabled");
        }
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const [searchText, setSearchText] = useState("");

  const filteredData = tableData.filter((item) => {
    const searchTextMatch = searchText
      ? item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.mobile.toLowerCase().includes(searchText.toLowerCase()) ||
        item.percent.toString().includes(searchText) ||
        item.id.toString().includes(searchText)
      : true;

    const agentCodeMatch = selectedAgentCode
      ? item.code === selectedAgentCode
      : true;

    return searchTextMatch;
  });

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModalCol"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">Gold Percentage</div>
              <div class="modal-body">
                <label>Percentage</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setPercevalue(e.target.value)}
                  value={percevalue}
                />
               
                <label>Date</label>

                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  value={selectedDate}
                />
                <label>Time</label>

                <input
                  type="time"
                  className="form-control"
                  onChange={(e) => setSelectedTime(e.target.value)}
                  value={selectedTime}
                />
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={() => deletes("occcol")}
                  data-bs-dismiss="modal"
                >
                  Update
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="page-title">
              <ToastContainer />
              <div class="row">
                <div class="col-sm-6">
                  <h3>Users</h3>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to={"/"}>
                        <i data-feather="home"></i>Dashboard
                      </Link>
                    </li>
                    <li class="breadcrumb-item">Users</li>
                    {/* <li class="breadcrumb-item active">Users</li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid" style={{ height: "320px !important" }}>
            <div class="select2-drpdwn">
              <div class="row">
                <div class="col-md-12">
                  <div class="card" style={{ minHeight: "320px" }}>
                    <div class="card-header pb-0">
                      <h5 class="card-title">Users</h5>
                    </div>
                    <div class="card-body o-hidden">
                      {/* <Select
                        value={selectedAgentCode}
                        onChange={handleAgentChange}
                        options={options}
                        isSearchable={true}
                      /> */}

                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        subHeader
                        subHeaderComponent={
                          <input
                            type="text"
                            placeholder="Search"
                            class="form-control"
                            style={{ width: "200px" }}
                            value={searchText}
                            onChange={handleSearch}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGold;
