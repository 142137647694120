import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from 'react-data-table-component';
import { PRO_BASE_URL } from "./pro_config";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../pages/loader";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { param } from "jquery";
import Category from "./category";

const AddCollection = (props) => {
    const { pid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedId, setSelectedId] = useState("");
    const [tableData, setTableData] = useState([]);
    const [tableCol, setTableCol] = useState([]);
    const [checkboxes, setCheckboxes] = useState([]);
    const [collection, setCollection] = useState([])
    const [colId, setColId] = useState("")
    const [selectedValue, setSelectedValue] = useState('');
    const [stext, setStext] = useState('');
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedIds, setSelectedIds] = useState([])
    const [searchText, setSearchText] = useState('');
    const [searchTextCol, setSearchTextCol] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState('');

    useEffect(() => {
        abc();
    }, []);

    const initialState = {
        proname: "", id: "", images: []
    }

    const [state, setState] = useState(initialState)
    const { proname, id, images } = state;

    let name, value
    const handleChange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });
        //console.log(state)
    };
    const abc = () => {
        axios
            .post(PRO_BASE_URL + "get_data.php")
            .then(function (data) {
                //console.log(data)
                if (data.data.message == "radha") {

                    if (data.data.collection.length > 0) {
                        setTableData(data.data.collection);
                    }
                    if (data.data.coldata.length > 0) {
                        setTableCol(data.data.coldata);
                    }
                    setIsLoading(false);
                }
                setIsLoading(false);
            })

            .catch(error => console.error(error))

    }

    const deletes = (page) => {

        axios
            .post(PRO_BASE_URL + "del_data.php", { "id": selectedId, "data": page })
            .then(function (data) {
                //console.log(data)
                if (data.data.message === "radha") {
                    toast.success('Data Deleted Successfully', { position: toast.POSITION.TOP_RIGHT });
                    abc();
                } else if (data.data.message === "FIRST") {
                    toast.error('First Transaction can not delete', { position: toast.POSITION.TOP_RIGHT });

                } else {
                    toast.error('Data Not Delete', { position: toast.POSITION.TOP_RIGHT });

                }
            }
            )

            .catch(error => console.error(error))

    }

    const editData = (id) => {
        //console.log(id)
        axios
            .post(PRO_BASE_URL + "get_data.php", { "id": id })
            .then(function (data) {
                if (data.data.message == "radha") {
                    const productData = data.data.collection[0];
                    //console.log(productData)

                    setState({
                        id: productData.id,
                        proname: productData.name,

                        images: productData.img
                    });
                    //console.log(state)
                    setIsLoading(false);
                }
                setIsLoading(false);
            })

            .catch(error => console.error(error))

    }

    const getProduct = (id) => {
        setSelectedValue(id)
        setColId(id);
        //console.log(id)
        axios
            .post(PRO_BASE_URL + "get_collection.php", { "id": id, "page": "collection" })
            .then(function (data) {
                //console.log(data.data.message)
                if (data.data.message == "radha" && data.data.result.length > 0) {
                    setCollection(data.data.result);
                    setIsLoading(false);
                }
                setIsLoading(false);
            })

            .catch(error => console.error(error))
    }

    const columns = [
        { name: 'id', selector: row => row['id'], sortable: true, filterable: true },

        { name: 'Collection', selector: row => row['name'], sortable: true, filterable: true },
        {
            name: 'Image',
            cell: (row) => <img src={row.img} alt="Image" height={50} width={50} />,
            sortable: true,
            filterable: true
        },
        // {
        //     name: 'Add Products', button: true, cell: (row) => (<button class="btn btn-primary btn-sm" data-bs-toggle="modal" data-original-title="test" data-bs-target="#exampleModallg" onClick={() => getProduct(row.id)}>Add </button>),
        // },
        {
            name: 'Edit', button: true, cell: (row) => (<img src="assets/images/pen.png" onClick={() => editData(row.id)} height={20} width={20} />
            ),
        },
        {
            name: 'Delete', button: true, cell: (row) => (<img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.id)} data-bs-target="#exampleModal" height={20} width={20} />
            ),
        },
    ];

    const columnsCol = [
        { name: 'id', selector: row => row['id'], sortable: true, filterable: true },

        { name: 'Product', selector: row => row['name'], sortable: true, filterable: true },
        { name: 'Product Code', selector: row => row['code'], sortable: true, filterable: true },
        {
            name: 'Image',
            cell: (row) => <img src={row.img} alt="Image" height={50} width={50} />,
            sortable: true,
            filterable: true
        },
        { name: 'Collection', selector: row => row['collname'], sortable: true, filterable: true },


        {
            name: 'Delete', button: true, cell: (row) => (<img src="assets/images/bin.png" data-bs-toggle="modal" data-original-title="test" onClick={() => setSelectedId(row.colid)} data-bs-target="#exampleModalCol" height={20} width={20} />
            ),
        },
    ];

    // const handleCheckboxChange = (id) => {
    //     setCollection(prevCollection =>
    //         prevCollection.map(item =>
    //             item.id === id ? { ...item, in: item.in === "true" ? "false" : "true" } : item
    //         )
    //     );
    // };


    const handleCheckboxChange = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };
    const handleDropdownChange = (e) => {
        setSelectedDropdownValue(e.target.value)
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleSearchCol = (e) => {
        setSearchTextCol(e.target.value);
    };
    const filteredData = tableData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())

    );

    const filteredColData = tableCol.filter((item) =>
        item.collid.includes(selectedDropdownValue) ||
        item.name.toLowerCase().includes(searchTextCol.toLowerCase()) ||
        item.code.toLowerCase().includes(searchTextCol.toLowerCase())
    );

    const filtercol = collection.filter((item) =>
        item.name.toLowerCase().includes(stext.toLowerCase()) ||
        item.code.includes(stext)
    );

    const handleImageChange = (event) => {
        const files = event.target.files;
        const imagesArray = Array.from(files);
        setState({ ...state, images: imagesArray });
        selectedImages(imagesArray)
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const AddProduct = async (e) => {

        const formData = new FormData();
        formData.append('id', state.id);
        formData.append('image', selectedFile);
        formData.append('proname', state.proname);

        try {
            const response = await axios.post(PRO_BASE_URL + 'add_collection.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            //console.log('Image uploaded successfully:', response.data);
            if (response.data.message == "radha") {
                setSelectedFile(null)
                toast.success("Collection Added Successfully..")
                abc();
                setState({ "proname": "", images: "" })
            }else{
                toast.error("Try Again");

            }
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error("Try Again");
            // Handle error: show an error message, retry upload, etc.
        }
    };

    const addPro = () => {
        const select = collection.map(item => item.id);
        // setSelectedIds(select)
        //console.log(selectedIds + "  " + colId);
        axios
            .post(PRO_BASE_URL + "create.php", { "id": selectedIds, "cid": colId, "page": "collection" })
            .then(function (data) {
                //console.log(data)
                if (data.data.message == "radha") {
                    toast.success("Product added to collection");
                    setSelectedValue("");

                    setSelectedIds([]);
                    abc();
                } else {
                    toast.error("Products Not Added");
                }
                setIsLoading(false);
            })

            .catch(error => console.error(error))

    }

    const Cancel = () => {
        setSelectedFile(null)
        // abc();
        setState({ "proname": "", images: "" })
    }
    const margin = localStorage.getItem("margin")

    return (
        <div>

            <div class="page-body-wrapper">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure ?</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => deletes("collection")} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalCol" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure Collection?</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => deletes("col")} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModallg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">Add Products</div>

                            <div class="modal-body">
                                <select
                                    className="form-control col-md-3"
                                    style={{ maxWidth: "250px" }}
                                    onChange={(e) => getProduct(e.target.value)}
                                    value={selectedValue}

                                >
                                <option>Select Collection</option>
                                    {tableData.map((pro, i) => (
                                        <option key={i} value={pro.id}>{pro.name}</option>
                                    ))}
                                </select>

                                {selectedValue && (
                                    <div class="mt-10" style={{ marginTop: "10px" }}>
                                        <input type="text" placeholder="Search Products" onChange={(e) => setStext(e.target.value)} className="form-control" />
                                        <div style={{ marginTop: "10px" }}>
                                            {filtercol.map(checkbox => (
                                                <div key={checkbox.id}>
                                                    <input
                                                        type="checkbox"
                                                        id={checkbox.id}
                                                        checked={selectedIds.includes(checkbox.id)}
                                                        onChange={() => handleCheckboxChange(checkbox.id)}
                                                    />
                                                    <label htmlFor={checkbox.id}>
                                                        <img src={checkbox.img} alt="loading" height={70} width={70} />
                                                        <b>{checkbox.name}</b> - {checkbox.code}
                                                    </label>
                                                </div>
                                            ))}


                                        </div>
                                    </div>
                                )}


                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => addPro()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                <div class="page-body" style={{ marginLeft: "320px" }}>

                    <div class="container-fluid">
                        <div class="page-title">
                            <div class="row">
                                <div class="col-sm-6">
                                    <h3>Add Collection</h3>
                                    {/* {state} */}
                                </div>
                                <div class="col-sm-6">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="https://admin.pixelstrap.com/enzo/template/index.html">                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg></a></li>
                                        <li class="breadcrumb-item active">Add Collection</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid add-product">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="product-info">
                                            {/* <h5>Description</h5> */}
                                            <form>
                                                <div class="product-group">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Collection Name</label>
                                                                <input class="form-control" placeholder="Enter Product Name" type="text" onChange={handleChange} name="proname" value={state.proname} />
                                                                <span class="text-danger"></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Collection Image</label><br />

                                                                <input type="file" accept="image/*" onChange={handleFileChange} />
                                                                {selectedFile ? (
                                                                    <img
                                                                        src={URL.createObjectURL(selectedFile)}
                                                                        alt="Selected File"
                                                                        style={{ width: '200px', height: 'auto', margin: '5px' }}
                                                                    />
                                                                ) : ""}
                                                                {state.images != "" ? (
                                                                    <img
                                                                        src={state.images}
                                                                        alt={`image`}
                                                                        style={{ width: '200px', height: 'auto', margin: '5px' }}
                                                                    />
                                                                ) : ""}

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="mt-4">
                                                        <div class="row">
                                                            <div class="col-sm-12 text-end"><a class="btn btn-primary me-3" onClick={() => AddProduct()}>ADD </a>
                                                                <a class="btn btn-secondary" onClick={() => Cancel()}>Cancel</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 ">

                                <div class="card" style={{ minHeight: "250px" }}>
                                    <div class="card-header pb-0">
                                        <h5>Collections</h5><span></span>
                                    </div>
                                    {isLoading ? (
                                        <Loader />
                                    ) : (<>
                                        <div class="card-body">
                                            <div class="table-responsive">

                                                <DataTable
                                                    columns={columns}
                                                    data={filteredData}
                                                    pagination
                                                />
                                            </div>
                                        </div>

                                    </>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 ">

                                <div class="card" style={{ minHeight: "250px" }}>
                                    <div class="card-header pb-0">
                                        <h5>Products</h5><span></span>
                                    </div>


                                    {isLoading ? (
                                        <Loader />
                                    ) : (<>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <select
                                                        className="form-control col-md-3"
                                                        style={{ maxWidth: "250px" }}
                                                        onChange={handleDropdownChange}
                                                        value={selectedDropdownValue}
                                                    >
                                                        <option value={null}>All</option>
                                                        {tableData.map((pro, i) => (
                                                            <option key={i} value={pro.id}>{pro.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div class="col-md-2">
                                                    <button class="btn btn-primary " data-bs-toggle="modal" data-original-title="test" data-bs-target="#exampleModallg" >Add To Collection</button>

                                                </div>
                                            </div>
                                            <div class="table-responsive">

                                                <DataTable
                                                    columns={columnsCol}
                                                    data={filteredColData}
                                                    pagination
                                                    subHeader
                                                    subHeaderComponent={
                                                        <input type="text" placeholder="Search" class="form-control" style={{ width: "200px" }} value={searchTextCol} onChange={handleSearchCol} />
                                                    }

                                                />
                                            </div>
                                        </div>

                                    </>
                                    )}
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>


    )
}

export default AddCollection;