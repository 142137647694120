import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from "react-data-table-component";
import { PRO_BASE_URL } from "./pro_config";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../pages/loader";
// import AddCategory from "./add_product";

const Products = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedId, setSelectedId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);

  useEffect(() => {
    abc();
  }, []);

  const abc = () => {
    axios
      .post(PRO_BASE_URL + "get_pro_detail.php")
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTableData(data.data.result);
          setTableDatas(data.data.result);
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
    // setIsLoading(false);
  };

  const deletes = () => {
    axios
      .post(PRO_BASE_URL + "del_data.php", { id: selectedId, data: "products" })
      // .then(response => response.json())
      .then(function (data) {
        ////console.log(data)
        if (data.data.message === "radha") {
          toast.success("Data Deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          abc();
        } else if (data.data.message === "FIRST") {
          toast.error("First Transaction can not delete", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Data Not Delete", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })

      .catch((error) => console.error(error));
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  //   const handleCheckboxChange = (id) => {
  //     if (selectedCheckboxes.includes(id)) {
  //       // If the checkbox is already selected, remove it from the array
  //       setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== id));
  //     } else {
  //       // If the checkbox is not selected, add it to the array
  //       setSelectedCheckboxes([...selectedCheckboxes, id]);
  //     }
  //   };

  const columns = [
    // {
    //   name: "Active",
    //   cell: (row) => (
    //     <input
    //       type="checkbox"
    //       checked={selectedCheckboxes.includes(row.id) || row.status == 0}
    //       onChange={() => handleCheckboxChange(row.id)}
    //     />
    //   ),
    //   sortable: true, // You might not want sorting for checkbox column
    //   filterable: false, // You might not want filtering for checkbox column
    // },
    {
      name: "id",
      selector: (row) => row["id"],
      sortable: true,
      width:"80px",
      filterable: true,
    },

    {
      name: "Active (1 is deative)",
      selector: (row) => row["status"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Code",
      selector: (row) => row["code"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Products",
      selector: (row) => row["title"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Image",
      cell: (row) => <img src={row.img} alt="Image" height={50} width={50} />,
      sortable: true,
      filterable: true,
    },
    {
      name: "Image",
      cell: (row) => <img src={row.img2} alt="Image" height={50} width={50} />,
      sortable: true,
      filterable: true,
    },
    {
      name: "Image",
      cell: (row) => <img src={row.img3} alt="Image" height={50} width={50} />,
      sortable: true,
      filterable: true,
    },
    {
      name: "Price",
      selector: (row) => "₹ " + row["price"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Discount Price",
      selector: (row) => "₹ " + row["discount"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Description",
      selector: (row) => row["desc"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Stock",
      selector: (row) => row["stock"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <Link to={`/AddProducts?${row.id}`}>
          <img src="assets/images/pen.png" height={20} width={20} />
        </Link>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <img
          src="assets/images/bin.png"
          data-bs-toggle="modal"
          data-original-title="test"
          onClick={() => setSelectedId(row.id)}
          data-bs-target="#exampleModal"
          height={20}
          width={20}
        />
      ),
    },
    // { name: 'By', selector: row => row['emi_type'], sortable: true, filterable: true, hide: true }
  ];
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = tableData.filter((item) =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const addActive = () => {
    axios
      .post(PRO_BASE_URL + "manage_active.php", {
        id: selectedCheckboxes,
        data: "products",
      })
      // .then(response => response.json())
      .then(function (data) {
        ////console.log(data)
        if (data.data.message === "radha") {
          toast.success("Data Deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          abc();
        } else if (data.data.message === "FIRST") {
          toast.error("First Transaction can not delete", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Data Not Delete", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })

      .catch((error) => console.error(error));
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleCheckboxChange = (productId) => {
    // Check if the product is already selected
    const isSelected = selectedProducts.includes(productId);
    if (isSelected) {
      // If already selected, remove it from the array
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    } else {
      // If not selected, add it to the array
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">Are You Sure ?</div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={() => deletes(selectedId)}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModalTest"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">Active Products</div>
              {selectedProducts}
              {tableDatas.map(
                (item, i) =>
                  ( 
                    item.status == 0 && // Only display products where status is 0
                    <div key={i}>
                      <input
                        type="checkbox"
                        id={item.id}
                        checked={selectedProducts.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                      <label htmlFor={item.id}>
                        <img src={item.img} alt="img" height="50px" />
                        {item.title}
                      </label>
                    </div>
                  )
              )}
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={() => deletes(selectedId)}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {isLoading ? <Loader/> : 
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="col-sm-12 ">
              <div class="card" style={{ minHeight: "250px" }}>
                <div class="card-header pb-0">
                  <h5>Products</h5>

                  <span></span>
                </div>
                {/* <div class="row">
                  <div class="col-md-2" style={{ display: "flex" }}>
                    <button
                      class="btn btn-primary btn-sm"
                      data-bs-toggle="modal"
                      data-original-title="test"
                      data-bs-target="#exampleModalTest"
                      style={{ width: "180px", margin: "auto" }}
                    >
                      Activation
                    </button>
                  </div>
                  <div class="col-md-2">
                    <button
                      class="btn btn-primary btn-sm"
                      style={{ width: "180px", margin: "auto" }}
                      onClick={() => addActive()}
                    >
                      Deactivation
                    </button>
                  </div>
                </div> */}

                {isLoading ? (
                                    <Loader />
                                ) : (<>
                <div class="card-body">
                  <div class="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      pagination
                      subHeader
                      subHeaderComponent={
                        <input
                          type="text"
                          placeholder="Search"
                          class="form-control"
                          style={{ width: "200px" }}
                          value={searchText}
                          onChange={handleSearch}
                        />
                      }
                    />
                  </div>
                </div>

                </>
                )}
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default Products;
