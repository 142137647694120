import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from "react-data-table-component";
import { PRO_BASE_URL } from "./pro_config";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../pages/loader";
const Category = () => {
  // const { id } = useParams();
  // //console.log(id)

  const url = window.location.href; // Get the current URL
  const datas = url.split("?");
  const id = datas[1];
  const [isLoading, setIsLoading] = useState(true);

  const [selectedId, setSelectedId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDataUser, setTableDataUser] = useState([]);

  ////console.log(sesspass)

  useEffect(() => {
    abc();
  }, []);

  const abc = () => {
    ////console.log(deleteData)
    axios
      .post(PRO_BASE_URL + "get_order_detail.php", { oid: id })
      .then(function (data) {
        //console.log(data);
        if (data.data.message == "radha") {
          setTableData(data.data.data);
          setTableDataUser(data.data.trans_result[0]);
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
    // setIsLoading(false);
  };

  const columns = [
    {
      name: "id",
      selector: (row) => row["id"],
      sortable: true,
      filterable: true,
    },

    {
      name: "Category",
      selector: (row) => row["name"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Image",
      cell: (row) => <img src={row.img} alt="Image" height={50} width={50} />,
      sortable: true,
      filterable: true,
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <img
          src="assets/images/bin.png"
          data-bs-toggle="modal"
          data-original-title="test"
          onClick={() => setSelectedId(row.id)}
          data-bs-target="#exampleModal"
          height={20}
          width={20}
        />
      ),
    },
    // { name: 'By', selector: row => row['emi_type'], sortable: true, filterable: true, hide: true }
  ];
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = tableData.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  // const conditionalRowStyles = (row) => {
  //     // You can use the row index to determine if it's even or odd
  //     const index = filteredData.indexOf(row);
  //     return index % 2 === 0 ? { backgroundColor: 'green' } : { backgroundColor: 'red' };
  //   };
  const customStyles = {
    cells: {
      style: {}, // Add common styles for all cells in the 'Type' column if needed
      classes: "badge badge-primary", // Apply the class to all cells in the 'Type' column
    },
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        {/* <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">Are You Sure ?</div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" onClick={() => deletes()} data-bs-dismiss="modal">Yes</button>
                                <button class="btn btn-dark" type="button" data-bs-dismiss="modal">No</button>

                            </div>
                        </div>
                    </div>
                </div> */}
        <ToastContainer />
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="col-sm-12 ">
              <div class="container invoice">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-body">
                        <div>
                          <div>
                            <div class="row invo-header">
                              <div class="col-sm-6">
                                <div class="d-flex">
                                  <div class="d-flex-left">
                                    <a href="https://admin.pixelstrap.com/enzo/template/index.html">
                                      <img
                                        class="d-flex-object img-60"
                                        src="./Enzo - Premium Admin Template_files/logo-icon.png"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <div class="flex-grow-1 m-l-20">
                                    <h4 class="d-flex-heading f-w-600">
                                      Manglik Gold
                                    </h4>
                                    <p>
                                      contact@manglikgold.com
                                      <br />
                                      <span class="digits">
                                        +91 79902 02540
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="text-md-end text-xs-center">
                                  <h3>
                                    #
                                    <span class="digits counter">
                                      {tableDataUser.orderid}
                                    </span>
                                  </h3>
                                  <p>
                                    Issued:{" "}
                                    <span class="digits">
                                      {tableDataUser.date}
                                    </span>
                                    <br />
                                    Payment Type:{" "}
                                    <span class="digits">
                                      {tableDataUser.paymethod}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row invo-profile">
                            <div class="col-xl-4">
                              <div class="invo-profile-left">
                                <div class="d-flex">
                                  <div class="d-flex-left">
                                    <img
                                      class="d-flex-object rounded-circle img-60"
                                      src="./Enzo - Premium Admin Template_files/1.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <div class="flex-grow-1 ms-3">
                                    <h4 class="d-flex-heading f-w-600">
                                      {tableDataUser.name}
                                    </h4>
                                    <p className="mb-0">{tableDataUser.ad1}</p>
                                    <p>
                                      {tableDataUser.email}
                                      <span class="digits">
                                        {tableDataUser.mobile}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-8">
                              <div class="invo-profile-right">
                                <div id="project">
                                  {tableDataUser.awb && (
                                    <>
                                      {" "}
                                      <h6>Download PDF</h6>
                                      <a
                                        href={tableDataUser.labels} // Set the URL of the PDF file
                                        className="btn btn-primary"
                                        download={tableDataUser.labels} // Set the filename for download
                                      >
                                        PDF
                                      </a>
                                      <p className="mb-0">AWB Number : {tableDataUser.awb}</p>
                                      <p>Product Status : <b>{tableDataUser.status}</b></p>

                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              class="table-responsive invoice-table"
                              id="table"
                            >
                              <table class="table table-bordered table-striped">
                                <tbody>
                                  <tr>
                                    <td class="item">
                                      <h6 class="py-2 mb-0">
                                        Item Description
                                      </h6>
                                    </td>
                                    <td class="Hours">
                                      <h6 class="py-2 mb-0">Qauntity</h6>
                                    </td>
                                    <td class="Rate">
                                      <h6 class="py-2 mb-0">Price</h6>
                                    </td>
                                    <td class="subtotal">
                                      <h6 class="py-2 mb-0">Sub-total</h6>
                                    </td>
                                  </tr>

                                  {tableData.map((pro, index) => (
                                    <tr key={index}>
                                      <td>
                                        <label>{pro.name}</label>
                                        <p class="m-0">{pro.desc}</p>
                                      </td>
                                      <td>
                                        <p class="itemtext digits">{pro.qau}</p>
                                      </td>
                                      <td>
                                        <p class="itemtext digits">
                                          ₹{pro.total}
                                        </p>
                                      </td>
                                      <td>
                                        <p class="itemtext digits">
                                          ₹{pro.qau * pro.total}
                                        </p>
                                      </td>
                                    </tr>
                                  ))}
                                {tableDataUser.coupon != 0 && <> <tr>
                                    <td></td>
                                    <td></td>
                                    <td class="Rate">
                                      <h6 class="mb-0 p-2">Coupon Code</h6>
                                    </td>
                                    <td class="payment digits">
                                      <h6 class="mb-0 p-2">
                                        ₹{tableDataUser.coupon} ({tableDataUser.ccode})
                                      </h6>
                                    </td>
                                  </tr></>}
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td class="Rate">
                                      <h6 class="mb-0 p-2">Total</h6>
                                    </td>
                                    <td class="payment digits">
                                      <h6 class="mb-0 p-2">
                                        ₹
                                        {parseInt(tableDataUser.price) -
                                          parseInt(tableDataUser.coupon)}
                                      </h6>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/* <div class="row mt-3">
                              <div class="col-md-8">
                                <div>
                                  <p class="legal">
                                    <strong>
                                      Thank you for your business!
                                    </strong>
                                    &nbsp; Payment is expected within 31 days;
                                    please process this invoice within that
                                    time. There will be a 5% interest charge per
                                    month on late invoices.
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <form class="text-end invo-pal">
                                  <input
                                    type="image"
                                    src="./Enzo - Premium Admin Template_files/paypal.png"
                                    name="submit"
                                    alt="PayPal - The safer, easier way to pay online!"
                                  />
                                </form>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div class="col-sm-12 text-center mt-3">
                          {/* <button class="btn btn btn-primary me-2" type="button" onclick="myFunction()">Print</button>
                                                    <button class="btn btn-secondary" type="button">Cancel</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
